import React from 'react';
import Therapist from '../../assets/woman_therapist.webp';
import { Link } from 'react-router-dom';
import { Link as LinkRoll } from "react-scroll";

const Cocaine = () => {
  return (
    <div className='addiction'>
      <div className='addictionContent'>
        <div className='addictionFlex'>
          <div className='addictionText'>
            <h1>Understanding Cocaine Addiction: Symptoms, Effects, and Treatment</h1>
            <p>Cocaine addiction is a chronic condition characterized by the compulsive use of cocaine despite harmful consequences. Cocaine is a powerful stimulant that affects the central nervous system, leading to intense euphoria, increased energy, and heightened alertness. However, it also poses significant health risks and can lead to severe physical and psychological dependence.</p>
            <div className='addictionBtn'>
              <div className='hero-btn1'>
                <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                  <Link to="/contact">
                    <button>Make an appointment</button>
                  </Link>
                </LinkRoll>
              </div>

            </div>
          </div>

          <div className='addictionImage'>
            <img src={Therapist} alt="activities" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cocaine;
